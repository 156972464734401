import React, { useState, useEffect } from "react";
import { api } from "../../services/baseUrl";

const FullStacksPage = () => {
  const [stacks, setStacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    setLoading(true);

    api
      .get("/stackslist", {
        params: {
          page: currentPage,
          limit: itemsPerPage,
        },
      })
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setStacks(response.data.data);
          setTotalPages(response.data.last_page);
        } else {
          setStacks([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching stacks:", error);
        setStacks([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  const renderLevelBar = (level) => {
    const levels = [1, 2, 3, 4, 5];
    return (
      <div className="flex items-center space-x-1">
        {levels.map((lvl) => (
          <div
            key={lvl}
            className={`h-2 w-2 ${
              lvl <= level ? "bg-[#e1927f]" : "bg-gray-300"
            } rounded-full transition-all`}
          ></div>
        ))}
      </div>
    );
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="w-full min-h-screen bg-[#44427d] py-16 text-white mt-10">
      <div className="max-w-screen-xl mx-auto px-8 text-center md:text-left">
        <h2 className="text-5xl md:text-7xl tracking-wider uppercase text-[#e1927f] font-bold mb-12">
          Liste complète des technologies
        </h2>

        {loading ? (
          <p className="text-center text-xl font-light text-[#f5f5f5]">
            Chargement...
          </p>
        ) : (
          <>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
              {Array.isArray(stacks) && stacks.length > 0 ? (
                stacks.map(
                  ({
                    id_stack,
                    name_stack,
                    level,
                    logo,
                    description_stack,
                  }) => (
                    <div
                      key={id_stack}
                      className="flex flex-col items-center justify-center p-6 bg-white rounded-2xl shadow-lg transform transition-all hover:scale-105 hover:shadow-2xl hover:translate-y-[-4px] group"
                    >
                      <div className="relative w-20 h-20 mb-6">
                        <img
                          src={`https://api.leproutludovic.fr/storage/uploads/stacks/${logo}`}
                          alt={name_stack}
                          className="w-full h-full object-contain group-hover:opacity-80 transition-all duration-200"
                        />
                      </div>
                      <h3 className="font-semibold text-2xl text-[#44427d] group-hover:text-[#e1927f] transition-all duration-300 mb-4">
                        {name_stack}
                      </h3>
                      {renderLevelBar(level)}

                      {description_stack && (
                        <p className="mt-4 text-lg text-[#44427d] max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl text-center sm:text-left leading-relaxed overflow-hidden overflow-ellipsis">
                          {description_stack}
                        </p>
                      )}
                    </div>
                  )
                )
              ) : (
                <p className="text-center text-xl font-light text-[#f5f5f5]">
                  Aucune stack disponible.
                </p>
              )}
            </div>

            <div className="flex justify-center mt-8 space-x-2">
              <button
                className="px-4 py-2 bg-[#e1927f] text-white rounded-lg disabled:bg-gray-300 transition-all"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Précédent
              </button>

              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={`px-4 py-2 rounded-lg transition-all ${
                    currentPage === i + 1
                      ? "bg-[#e1927f] text-white"
                      : "bg-gray-200 text-[#44427d]"
                  }`}
                >
                  {i + 1}
                </button>
              ))}

              <button
                className="px-4 py-2 bg-[#e1927f] text-white rounded-lg disabled:bg-gray-300 transition-all"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Suivant
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FullStacksPage;
