import React, { useState } from "react";
import Contactus from "../../assets/images/Contact/Contact.png";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { api } from "../../services/baseUrl";

const FifthContainer = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("/contact", formData);
      setSuccessMessage("Votre message a été envoyé avec succès.");
      setErrorMessage("");
      setFormData({ name: "", phone: "", email: "", message: "" });
    } catch (error) {
      setErrorMessage(
        "Une erreur est survenue lors de l'envoi de votre message."
      );
      setSuccessMessage("");
    }
  };

  return (
    <div id="contact" className="w-full bg-[#e1927f] py-16 text-white">
      <div className="max-w-screen-xl mx-auto px-8 text-center md:text-left">
        <h2 className="text-5xl md:text-7xl text-[#44427d] tracking-wider uppercase font-bold mb-6">
          Contactez-moi
        </h2>

        <div className="flex flex-col md:flex-row gap-8 shadow-xl bg-white rounded-xl p-8">
          {/* Image and Social Links */}
          <div className="w-full md:w-1/2 h-full flex flex-col items-center md:items-start">
            <img
              src={Contactus}
              alt="portfolio développeur web full-stack"
              className="rounded-xl mb-4"
              width="500"
              height="300"
            />
            <p className="pt-2 pb-8 text-gray-700">
              Je suis passionné par l'innovation et enthousiaste à l'idée de
              relever de nouveaux défis. En tant que professionnel en début de
              carrière, je suis prêt à apporter mon énergie et ma détermination
              à votre équipe. Contactez-moi pour discuter de comment je peux
              contribuer à vos projets.
            </p>

            <div className="flex gap-6">
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center p-3 bg-[#44427d] text-white rounded-full shadow-md hover:scale-110 transition-all"
              >
                <FaLinkedin size={30} />
              </a>
              <a
                href="https://github.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center p-3 bg-[#44427d] text-white rounded-full shadow-md hover:scale-110 transition-all"
              >
                <FaGithub size={30} />
              </a>
            </div>
          </div>

          {/* Contact Form */}
          <div className="flex flex-col p-4 w-full md:w-1/2 mx-auto h-full">
            {errorMessage && (
              <div className="text-[#e1927f]  text-center mb-4">
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div className="text-[#e1927f] text-center mb-4">
                {successMessage}
              </div>
            )}
            <form onSubmit={handleSubmit} className="flex flex-col w-full">
              <label className="text-sm text-gray-700 font-light py-2">
                Nom
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="border-2 rounded-lg p-3 focus:outline-none border-[#44427d] mb-4 text-black"
                required
              />

              <label className="text-sm text-gray-700 font-light py-2">
                Téléphone
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="border-2 rounded-lg p-3 focus:outline-none border-[#44427d] mb-4 text-black"
                required
              />

              <label className="text-sm text-gray-700 font-light py-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border-2 rounded-lg p-3 focus:outline-none border-[#44427d] mb-4 text-black"
                required
              />

              <label className="text-sm text-gray-700 font-light py-2">
                Message
              </label>
              <textarea
                name="message"
                rows="6"
                value={formData.message}
                onChange={handleChange}
                className="border-2 rounded-lg p-3 focus:outline-none border-[#44427d] mb-6 text-black"
                required
              ></textarea>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-[#44427d] text-white px-6 py-3 font-bold uppercase rounded-md tracking-wider hover:scale-105 transition-all"
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthContainer;
