import React, { useState, useEffect, useContext } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { UserContext } from "../../hooks/UserContext";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const { isAuthenticated, login, logout } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [pageScroll, setPageScroll] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("tokenlpr");
    if (token) {
      login({ token });
    }

    const handleScroll = () => setPageScroll(window.scrollY >= 90);
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("tokenlpr");
    logout(); // Met à jour l'état global
    navigate("/"); // Redirection vers la page d'accueil
  };

  const handleLogin = () => {
    navigate("/login");
    setIsOpen(false); // Ferme le menu hamburger après la connexion
  };

  const handleLinkClick = (path) => {
    if (path.startsWith("#")) {
      if (location.pathname !== "/") {
        // Si nous ne sommes pas sur la page d'accueil, naviguez-y d'abord
        navigate("/");

        // Une fois sur la page d'accueil, effectuez le défilement après un petit délai
        setTimeout(() => {
          const section = document.querySelector(path);
          section && section.scrollIntoView({ behavior: "smooth" });
        }, 300); // Délai pour laisser le temps à la navigation
      } else {
        // Si nous sommes déjà sur la page d'accueil, scroller directement
        const section = document.querySelector(path);
        section && section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate(path); // Pour les autres chemins
    }
    setIsOpen(false); // Ferme le menu mobile
  };

  const links = [
    { id: 1, link: "À propos de moi", path: "#moi" },
    { id: 2, link: "portfolio", path: "#portfolio" },
    { id: 3, link: "technologies", path: "#experience" },
    { id: 4, link: "contact", path: "#contact" },
    { id: 5, link: "github", path: "/github" },
  ];

  if (isAuthenticated) {
    links.push({ id: 6, link: "Panel Admin", path: "/admin" });
  }

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 duration-300 ease-in ${
        pageScroll
          ? "bg-[#44427d] shadow-lg text-white"
          : "bg-[#e1927f] text-white"
      }`}
    >
      <nav className="w-full px-6 py-4 flex items-center justify-between focus:outline-none select-none">
        <div className="flex items-center space-x-2 mr-5">
          <div className="w-10 h-10 bg-[#e1927f] border border-[#44427d] rounded-full flex items-center justify-center shadow-lg">
            <h2
              className="text-2xl font-bold text-[#44427d] cursor-pointer"
              onClick={() => {
                if (location.pathname === "/") {
                  // Si on est déjà sur la page d'accueil, scroller en haut
                  window.scrollTo({ top: 0, behavior: "smooth" });
                } else {
                  // Sinon, naviguer vers la page d'accueil
                  handleLinkClick("/");
                }
              }}
            >
              LL
            </h2>
          </div>
        </div>

        {/* Navbar Links */}
        <div
          className={`absolute top-16 left-0 w-full bg-[#44427d] md:bg-transparent p-6 md:p-0 md:static flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-8 ${
            isOpen ? "block" : "hidden"
          } md:block`}
        >
          {links.map(({ id, link, path }) => (
            <span
              key={id}
              className={`font-medium transition-all duration-300 cursor-pointer focus:outline-none ${
                pageScroll
                  ? "text-white hover:text-[#e1927f]"
                  : "text-white hover:text-[#44427d]"
              }`}
              onClick={() => handleLinkClick(path)}
            >
              {link}
            </span>
          ))}
        </div>

        <div className="ml-auto flex items-center space-x-4">
          {/* Bouton de connexion visible sur les grands écrans */}
          <div className="hidden md:block">
            {!isAuthenticated ? (
              <button
                onClick={handleLogin}
                className={`text-white hover:text-[#44427d] font-medium px-4 py-2 rounded-lg border border-white hover:border-[#e1927f] transition-all duration-300 ${
                  pageScroll
                    ? "bg-[#44427d] text-white hover:bg-[#e1927f]"
                    : "bg-transparent text-white hover:bg-[#e1927f] border-white hover:border-[#44427d]"
                }`}
              >
                Connexion
              </button>
            ) : (
              <button
                onClick={handleLogout}
                className={`text-white hover:text-[#44427d] font-medium px-4 py-2 rounded-lg border border-[#e1927f] transition-all duration-300 ${
                  pageScroll
                    ? "bg-[#44427d] text-white hover:bg-[#e1927f]"
                    : "bg-transparent text-white hover:bg-[#e1927f] border-white hover:border-[#44427d]"
                }`}
              >
                Déconnexion
              </button>
            )}
          </div>

          {/* Menu hamburger */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden text-white hover:text-[#e1927f] focus:outline-none"
          >
            {isOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
          </button>
        </div>
      </nav>

      {/* Menu mobile avec le bouton de connexion en bas */}
      {isOpen && (
        <div className="absolute bottom-0 left-0 w-full bg-[#44427d] p-6 md:hidden flex flex-col space-y-4">
          {links.map(({ id, link, path }) => (
            <span
              key={id}
              className={`font-medium transition-all duration-300 cursor-pointer text-white hover:text-[#e1927f]`}
              onClick={() => handleLinkClick(path)}
            >
              {link}
            </span>
          ))}
          {/* Bouton de connexion dans le menu mobile */}
          {!isAuthenticated ? (
            <button
              onClick={handleLogin}
              className="w-full text-white font-medium py-2 rounded-lg border border-white hover:border-[#e1927f] transition-all duration-300"
            >
              Connexion
            </button>
          ) : (
            <button
              onClick={handleLogout}
              className="w-full text-white font-medium py-2 rounded-lg border border-[#e1927f] transition-all duration-300"
            >
              Déconnexion
            </button>
          )}
        </div>
      )}
    </header>
  );
};

export default Navbar;
