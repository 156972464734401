import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

const AllProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.leproutludovic.fr/api/projects",
          {
            params: {
              page: currentPage,
              limit: itemsPerPage,
            },
          }
        );
        setProjects(response.data.data);
        setTotalPages(response.data.last_page);
      } catch (error) {
        console.error("Erreur lors de la récupération des projets:", error);
      }
    };

    fetchProjects();
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="w-full min-h-screen bg-[#44427d] py-16 text-white">
      <div className="max-w-screen-xl mx-auto px-8 text-center md:text-left">
        <h2 className="text-5xl md:text-7xl tracking-wider uppercase text-[#44427d] font-bold mb-6">
          Tous les projets
        </h2>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map(
            ({
              id_project,
              name_project,
              image_project,
              description_project,
            }) => (
              <NavLink
                key={id_project}
                to={`/portfolio/${id_project}`}
                className="cursor-pointer group shadow-lg shadow-[#44427d] rounded-lg overflow-hidden hover:scale-105 bg-white transition-all duration-300 p-6 flex items-start space-x-4"
              >
                <div className="w-20 h-20 flex-shrink-0 overflow-hidden rounded-full bg-gray-200">
                  <img
                    src={`https://api.leproutludovic.fr/storage/uploads/projects/${image_project}`} // Chemin dynamique vers l'image
                    alt={name_project}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h2 className="text-left text-lg md:text-xl text-[#44427d] font-semibold group-hover:underline group-hover:text-[#e1927f] transition-all duration-300">
                    {name_project}
                  </h2>
                  <p className="text-left text-sm text-[#44427d] mt-2">
                    {description_project}
                  </p>
                </div>
              </NavLink>
            )
          )}
        </div>
        <div className="flex justify-center mt-8 space-x-2">
          <button
            className="px-4 py-2 bg-[#e1927f] text-white rounded-lg disabled:bg-gray-300 transition-all"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Précédent
          </button>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`px-4 py-2 rounded-lg transition-all ${
                currentPage === i + 1
                  ? "bg-[#e1927f] text-white"
                  : "bg-gray-200 text-[#44427d]"
              }`}
            >
              {i + 1}
            </button>
          ))}
          <button
            className="px-4 py-2 bg-[#e1927f] text-white rounded-lg disabled:bg-gray-300 transition-all"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllProjectsPage;
