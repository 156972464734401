import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/baseUrl";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("/login", {
        email,
        password,
      });

      const { token } = response.data.data.tokenlpr;
      localStorage.setItem("tokenlpr", token);

      navigate("/"); // Redirection vers la page d'accueil
    } catch (err) {
      setError("Email ou mot de passe incorrect");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#44427d] text-white">
      <div className="w-full max-w-md bg-white shadow-xl rounded-lg p-8">
        <div className="flex justify-center mb-6">
          <div className="w-16 h-16 bg-[#e1927f] rounded-full flex items-center justify-center shadow-lg">
            <h1 className="text-3xl font-bold text-white">LL</h1>
          </div>
        </div>
        <h2 className="text-2xl font-bold text-center text-[#44427d] mb-4">
          Connexion
        </h2>
        {error && <p className="text-[#e1927f] text-center mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-[#44427d] mb-1"
            >
              Adresse Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border text-[#44427d] border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#e1927f] focus:border-transparent"
              placeholder="exemple@email.com"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-[#44427d] mb-1"
            >
              Mot de Passe
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border text-[#44427d] border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#e1927f] focus:border-transparent"
              placeholder="Votre mot de passe"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#44427d] text-white font-medium py-2 rounded-lg hover:bg-[#e1927f] transition-all duration-300 shadow-md"
          >
            Se connecter
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
