import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../hooks/UserContext";
import { api } from "../../services/baseUrl";
import { useNavigate } from "react-router-dom";

const AdminStacks = () => {
  const { user, token } = useContext(UserContext);
  const [stacks, setStacks] = useState([]);
  const [nameStack, setNameStack] = useState("");
  const [descriptionStack, setDescriptionStack] = useState("");
  const [level, setLevel] = useState(1);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [stacksPerPage] = useState(5);
  const [isEditing, setIsEditing] = useState(false);
  const [editingStackId, setEditingStackId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStacks = async () => {
      try {
        const response = await api.get("/stacks", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStacks(response.data);
      } catch (error) {
        console.error("Error fetching stacks:", error);
      }
    };

    fetchStacks();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    // Validation des champs
    if (!nameStack || !level) {
      setErrorMessage("Le nom et le niveau sont requis.");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name_stack", nameStack);
      formData.append("description_stack", descriptionStack);
      formData.append("level", level);
      if (logo) formData.append("logo", logo);

      // Ajout de _method pour simuler une requête PUT
      if (isEditing) {
        formData.append("_method", "PUT");
        await api.post(`/stacks/${editingStackId}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setSuccessMessage("Stack mise à jour avec succès !");
      } else {
        await api.post("/stacks", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setSuccessMessage("Stack ajoutée avec succès !");
      }

      // Reset the form after successful submission
      setNameStack("");
      setDescriptionStack("");
      setLevel(1);
      setLogo(null);
      setIsEditing(false);
      setEditingStackId(null);

      // Refresh stacks list
      const response = await api.get("/stacks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStacks(response.data);
    } catch (error) {
      setErrorMessage("Erreur lors de l'ajout/mise à jour de la stack.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (stack) => {
    setNameStack(stack.name_stack);
    setDescriptionStack(stack.description_stack);
    setLevel(stack.level);
    setIsEditing(true);
    setEditingStackId(stack.id_stack);
  };

  const handleDelete = async (id_stack) => {
    try {
      await api.delete(`/stacks/${id_stack}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuccessMessage("Stack supprimée avec succès !");

      const response = await api.get("/stacks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStacks(response.data);
    } catch (error) {
      setErrorMessage("Erreur lors de la suppression de la stack.");
      console.error(error);
    }
  };

  const indexOfLastStack = currentPage * stacksPerPage;
  const indexOfFirstStack = indexOfLastStack - stacksPerPage;
  const currentStacks = stacks.slice(indexOfFirstStack, indexOfLastStack);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderLevelBar = (level) => {
    const levels = [1, 2, 3, 4, 5];
    return (
      <div className="flex">
        {levels.map((lvl) => (
          <div
            key={lvl}
            className={`h-4 w-4 ${
              lvl <= level ? "bg-[#e1927f]" : "bg-gray-300"
            } mx-1 rounded-full`}
          ></div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full min-h-screen mt-10 bg-[#44427d] text-white py-16">
      <div className="max-w-screen-xl mx-auto px-8">
        <h2 className="text-3xl md:text-5xl tracking-wider uppercase text-[#e1927f] font-bold mb-8">
          Gérer les Stacks
        </h2>
        <button
          onClick={() => navigate("/admin")}
          className="mb-6 px-4 py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
        >
          Retour à l'administration
        </button>
        <form onSubmit={handleSubmit} className="space-y-4 mb-8">
          <div>
            <label className="block text-lg mb-2">Nom de la Stack</label>
            <input
              type="text"
              value={nameStack}
              onChange={(e) => setNameStack(e.target.value)}
              className="w-full p-2 rounded bg-white text-black"
              required
            />
          </div>
          <div>
            <label className="block text-lg mb-2">
              Description de la Stack
            </label>
            <textarea
              value={descriptionStack}
              onChange={(e) => setDescriptionStack(e.target.value)}
              className="w-full p-2 rounded bg-white text-black"
            />
          </div>
          <div>
            <label className="block text-lg mb-2">Niveau de la Stack</label>
            {renderLevelBar(level)}
            <input
              type="number"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              min="1"
              max="5"
              className="w-full p-2 rounded bg-white text-black"
              required
            />
          </div>
          <div>
            <label className="block text-lg mb-2">Logo de la stack</label>
            <input
              type="file"
              onChange={(e) => setLogo(e.target.files[0])}
              className="w-full p-2 rounded bg-white text-black"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full p-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
          >
            {loading
              ? "Ajout en cours..."
              : isEditing
              ? "Mettre à jour la Stack"
              : "Ajouter la Stack"}
          </button>
          {successMessage && (
            <p className="text-green-500 mt-4">{successMessage}</p>
          )}
          {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
        </form>
        <div>
          <h3 className="text-2xl font-bold mb-4">Stacks existantes :</h3>
          <ul>
            {Array.isArray(currentStacks) && currentStacks.length > 0 ? (
              currentStacks.map((stack) => (
                <li
                  key={stack.id_stack}
                  className="mb-4 p-4 bg-white text-black rounded shadow"
                >
                  <div className="flex flex-col sm:flex-row justify-between items-center sm:space-x-4">
                    <div className="w-full sm:w-auto">
                      <h4 className="text-lg font-bold">{stack.name_stack}</h4>
                      <p>{stack.description_stack}</p>

                      <div className="mb-4">{renderLevelBar(stack.level)}</div>
                    </div>
                    <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                      <button
                        onClick={() => handleEdit(stack)}
                        className="w-full sm:w-auto px-4 py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
                      >
                        Modifier
                      </button>
                      <button
                        onClick={() => handleDelete(stack.id_stack)}
                        className="w-full sm:w-auto px-4 py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p>Aucune stack trouvée.</p>
            )}
          </ul>
          <div className="flex justify-center mt-4">
            {Array.from(
              { length: Math.ceil(stacks.length / stacksPerPage) },
              (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => paginate(i + 1)}
                  className={`px-4 py-2 mx-1 rounded ${
                    currentPage === i + 1
                      ? "bg-[#f5674e] text-white"
                      : "bg-[#d97b6b] text-[#44427d]"
                  }`}
                >
                  {i + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStacks;
