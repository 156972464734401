import React from "react";
import FirstContainer from "./FirstContainer";
import SecondaryContainer from "./SecondaryContainer";
import TertiaryContainer from "./TertiaryContainer";
import FourThContainer from "./FourThContainer";
import FiveThContainer from "./FiveThContainer";

const Home = () => {
  return (
    <>
      <FirstContainer />
      <SecondaryContainer />
      <TertiaryContainer />
      <FourThContainer />
      <FiveThContainer />
    </>
  );
};

export default Home;
