import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../hooks/UserContext";
import { api } from "../../services/baseUrl";
import { useNavigate } from "react-router-dom";

const ProjectManager = () => {
  const { user, token } = useContext(UserContext);
  const [projects, setProjects] = useState([]);
  const [stacks, setStacks] = useState([]);
  const [newProject, setNewProject] = useState({
    title: "",
    description: "",
    demoUrl: "",
    stacks: [],
    image: null,
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  // Fetching projects
  const fetchProjects = async (page = 1) => {
    try {
      const response = await api.get(`/projects?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProjects(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching projects", error);
    }
  };

  // Fetching technology stacks
  const fetchStacks = async () => {
    try {
      const response = await api.get("/stacks", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStacks(response.data);
    } catch (error) {
      console.error("Error fetching stacks", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchStacks();
  }, []);

  // Handling input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
  };

  // Handling image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewProject({ ...newProject, image: file });
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // Adding a new project
  const handleAddProject = async (e) => {
    e.preventDefault();
    if (!newProject.title || !newProject.description) {
      setErrorMessage("Le titre et la description sont requis.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("name_project", newProject.title);
      formData.append("description_project", newProject.description_project);
      formData.append("url_demo", newProject.demoUrl);
      formData.append("id_user", user.id_user);
      newProject.stacks.forEach((stack) => {
        formData.append("stacks[]", stack);
      });
      if (newProject.image) {
        formData.append("image_project", newProject.image);
      }

      await api.post("/projects", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      setSuccessMessage("Projet ajouté avec succès.");
      setErrorMessage("");
      setNewProject({
        title: "",
        description: "",
        demoUrl: "",
        stacks: [],
        image: null,
      });
      setPreviewImage(null);
      fetchProjects(currentPage);
    } catch (error) {
      console.error("Error adding project", error);
      setErrorMessage("Erreur lors de l'ajout du projet.");
    }
  };

  // Editing an existing project
  const handleEditProject = (project) => {
    setIsEditing(true);
    setCurrentProject(project);
    setNewProject({
      title: project.name_project,
      description: project.description_project,
      demoUrl: project.url_demo,
      stacks: project.stacks.map((stack) => stack.id_stack),
      image: null,
    });
    setPreviewImage(null);
  };

  // Updating the project
  const handleUpdateProject = async (e) => {
    e.preventDefault();

    if (!newProject.title || !newProject.description) {
      setErrorMessage("Le titre et la description sont requis.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name_project", newProject.title);
      formData.append("description_project", newProject.description);
      formData.append("url_demo", newProject.demoUrl);
      formData.append("id_user", user.id_user);

      newProject.stacks.forEach((stack) => {
        formData.append("stacks[]", stack);
      });

      if (newProject.image) {
        formData.append("image_project", newProject.image);
      }

      // Add _method to simulate PUT request
      formData.append("_method", "PUT");

      await api.post(`/projects/${currentProject.id_project}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      setSuccessMessage("Projet mis à jour avec succès.");
      setErrorMessage("");
      setIsEditing(false);
      setCurrentProject(null);
      fetchProjects(currentPage);
    } catch (error) {
      console.error("Error updating project", error);
      setErrorMessage("Erreur lors de la mise à jour du projet.");
    }
  };

  // Deleting a project
  const handleDeleteProject = async (projectId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce projet ?")) {
      try {
        await api.delete(`/projects/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSuccessMessage("Projet supprimé avec succès.");
        fetchProjects(currentPage);
      } catch (error) {
        console.error("Error deleting project", error);
        setErrorMessage("Erreur lors de la suppression du projet.");
      }
    }
  };

  // Handling page change for pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchProjects(page);
  };

  // Handling stack selection
  const handleStackSelection = (e) => {
    const selectedStackId = e.target.value;
    if (!newProject.stacks.includes(selectedStackId)) {
      setNewProject({
        ...newProject,
        stacks: [...newProject.stacks, selectedStackId],
      });
    }
  };

  // Removing selected stack
  const handleRemoveStack = (stackId) => {
    setNewProject({
      ...newProject,
      stacks: newProject.stacks.filter((id) => id !== stackId),
    });
  };

  return (
    <div className="w-full min-h-screen mt-10 bg-[#44427d] text-white py-16">
      <div className="max-w-screen-xl mx-auto px-8">
        <h2 className="text-3xl md:text-5xl tracking-wider uppercase text-[#e1927f] font-bold mb-8">
          Gestion des projets
        </h2>
        <button
          onClick={() => navigate("/admin")}
          className="mb-6 px-4 py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
        >
          Retour à l'administration
        </button>
        {errorMessage && (
          <div className="text-red-500 mb-4">{errorMessage}</div>
        )}
        {successMessage && (
          <div className="text-green-500 mb-4">{successMessage}</div>
        )}

        <form
          onSubmit={isEditing ? handleUpdateProject : handleAddProject}
          className="bg-white p-6 rounded-lg shadow-lg mb-4"
        >
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-black"
            >
              Titre du projet
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={newProject.title}
              onChange={handleInputChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded-md w-full text-black"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-black"
            >
              Description du projet
            </label>
            <textarea
              id="description"
              name="description"
              value={newProject.description}
              onChange={handleInputChange}
              required
              className="mt-1 p-2 border border-gray-300 rounded-md w-full text-black"
            ></textarea>
          </div>

          <div className="mb-4">
            <label
              htmlFor="demoUrl"
              className="block text-sm font-medium text-black"
            >
              URL de démonstration
            </label>
            <input
              type="url"
              id="demoUrl"
              name="demoUrl"
              value={newProject.demoUrl}
              onChange={handleInputChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full text-black"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-black">
              Stacks
            </label>
            <select
              onChange={handleStackSelection}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full text-black"
            >
              <option value="">Sélectionnez un stack</option>
              {stacks.map((stack) => (
                <option key={stack.id_stack} value={stack.id_stack}>
                  {stack.name_stack}
                </option>
              ))}
            </select>
            <div className="mt-2 flex flex-wrap">
              {newProject.stacks.map((stackId) => {
                const stack = stacks.find(
                  (s) => s.id_stack === parseInt(stackId, 10)
                );
                return (
                  <span
                    key={stackId}
                    className="bg-[#e1927f] text-white rounded-full px-4 py-1 text-sm mr-2 mb-2 flex items-center"
                  >
                    {stack ? stack.name_stack : "Nom de stack introuvable"}
                    <button
                      type="button"
                      onClick={() => handleRemoveStack(stackId)}
                      className="ml-2 text-black"
                    >
                      ✕
                    </button>
                  </span>
                );
              })}
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="image"
              className="block text-sm font-medium text-black"
            >
              Image du projet
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full text-black"
            />
            {previewImage && (
              <img
                src={previewImage}
                alt="Image preview"
                className="mt-4 w-32 h-32 object-cover rounded-lg"
              />
            )}
          </div>

          <button
            type="submit"
            className="px-4 py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
          >
            {isEditing ? "Mettre à jour le projet" : "Ajouter le projet"}
          </button>
        </form>

        <div className="mt-8">
          <h3 className="text-2xl font-semibold text-[#e1927f] mb-4">
            Projets existants
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {projects.map((project) => (
              <div
                key={project.id_project}
                className="bg-white p-4 rounded-lg shadow-md"
              >
                <h4 className="text-xl font-semibold text-black">
                  {project.name_project}
                </h4>
                <p className="text-sm text-gray-500 truncate">
                  {project.description_project}
                </p>
                <div className="mt-4 flex justify-between items-center">
                  <button
                    onClick={() => handleEditProject(project)}
                    className="text-[#44427d] hover:underline"
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() => handleDeleteProject(project.id_project)}
                    className="text-red-500 hover:underline"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-6 flex justify-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] mr-4 disabled:opacity-50"
            >
              Précédent
            </button>
            <span className="text-white mx-2">Page {currentPage}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-4 py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] disabled:opacity-50"
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManager;
