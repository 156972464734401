import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link
import { api } from "../../services/baseUrl";

const FourThContainer = () => {
  const [stacks, setStacks] = useState([]); // Ensure it's always an array
  const [loading, setLoading] = useState(false);
  const [showAllStacks, setShowAllStacks] = useState(false); // State to toggle full stack list

  useEffect(() => {
    const fetchStacks = async () => {
      setLoading(true);
      try {
        const response = await api.get("/stackshome", {
          params: {
            _page: 1, // Initially load first page
            _limit: 8, // Limit to 8 stacks per page
          },
        });

        // Check if the response data is an array
        const fetchedStacks = Array.isArray(response.data) ? response.data : [];
        setStacks(fetchedStacks); // Set state to the array of stacks or empty array
      } catch (error) {
        console.error("Error fetching stacks:", error);
        setStacks([]); // Set empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchStacks();
  }, []);

  const renderLevelBar = (level) => {
    const levels = [1, 2, 3, 4, 5];
    return (
      <div className="flex">
        {levels.map((lvl) => (
          <div
            key={lvl}
            className={`h-4 w-4 ${
              lvl <= level ? "bg-[#e1927f]" : "bg-gray-300"
            } mx-1 rounded-full`}
          ></div>
        ))}
      </div>
    );
  };

  return (
    <div id="experience" className="w-full bg-[#44427d] py-16 text-white">
      <div className="max-w-screen-xl mx-auto px-8 text-center md:text-left">
        <h2 className="text-4xl sm:text-5xl md:text-7xl tracking-wider uppercase text-[#e1927f] font-bold mb-6 ">
          Technologies utilisées
        </h2>
        <p className="py-4 max-w-lg mx-auto text-lg font-light text-[#f5f5f5]">
          Voici les stacks modernes que j'utilise pour développer des
          applications web, accompagnées de mon estimation personnelle de
          compétence sur une échelle de 1 à 5.
        </p>

        {/* Link to navigate to a new page for full stack list */}
        <Link
          to="/fullstacks" // Define the route where the full list of stacks will be displayed
          className="text-[#e1927f] font-semibold hover:underline mb-6"
        >
          Voir la liste complète
        </Link>

        {loading ? (
          <p className="text-center text-xl font-light text-[#f5f5f5]">
            Chargement...
          </p>
        ) : (
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {stacks.map(({ id, name_stack, level, logo }, index) => (
              <div
                key={`${id}-${index}`} // Utiliser une combinaison de l'id et de l'index pour garantir l'unicité
                className="flex flex-col items-center justify-center p-6 shadow-lg rounded-xl bg-white group hover:scale-105 hover:shadow-2xl transition-all duration-300"
              >
                <div className="relative w-16 h-16 mb-4">
                  <img
                    src={`https://api.leproutludovic.fr/storage/uploads/stacks/${logo}`}
                    alt={name_stack}
                    className="w-full h-full object-contain transition-all duration-200 ease-in group-hover:opacity-80"
                  />
                </div>
                <h3 className="font-semibold text-xl text-[#44427d] group-hover:text-[#e1927f] transition-all duration-300">
                  {name_stack}
                </h3>
                {renderLevelBar(level)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FourThContainer;
