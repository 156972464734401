import { jwtDecode } from "jwt-decode";


function getToken() {
  if (typeof window !== "undefined") {
    return localStorage.getItem("tokenlpr");
  }
  return null;
}

let getDecodedToken = () => {
  const token = getToken();
  if (token) {
    return jwtDecode(token);
  } else {
    return false;
  }
};

let getExpiryTime = () => {
  const decodedToken = getDecodedToken();
  if (decodedToken && decodedToken.exp * 1000 > Date.now()) {
    return true;
  } else {
    localStorage.removeItem("tokenlpr");
    return false;
  }
};

let getEmail = () => {
  const decodedToken = getDecodedToken();
  if (getExpiryTime() && decodedToken) {
    return decodedToken.email;
  } else {
    return false;
  }
};

export default {
  getToken,
  getDecodedToken,
  getEmail,
  getExpiryTime,
};
