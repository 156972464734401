import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../../pages/home/";
import Navbar from "../globals/Navbar";
import Login from "../../pages/auth/Login";
import NotFound from "../globals/NotFound";
import Dashboard from "../../pages/admin/Dashboard";
import Projects from "../../pages/admin/Projects";
import Stacks from "../../pages/admin/Stacks";
import Users from "../../pages/admin/Users";
import ProjectDetails from "../../pages/projects/ProjectDetails";
import StacksList from "../../pages/stacks/StacksList";
import GitHubStats from "../../pages/github/GitHubStats";
import ProjectsList from "../../pages/projects/ProjectList";
import ProtectedRoute from "../globals/ProtectedRoute";

const RouteContainer = () => {
  return (
    <Router>
      <Navbar />
      <div className="root">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          {/* Admin routes */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute element={<Dashboard />} roleRequired={3} />
            }
          />
          <Route
            path="/admin/projects"
            element={<ProtectedRoute element={<Projects />} roleRequired={3} />}
          />
          <Route
            path="/admin/stacks"
            element={<ProtectedRoute element={<Stacks />} roleRequired={3} />}
          />
          <Route
            path="/admin/users"
            element={<ProtectedRoute element={<Users />} roleRequired={3} />}
          />
          <Route path="/portfolio/:id_project" element={<ProjectDetails />} />
          <Route path="/fullstacks" element={<StacksList />} />
          <Route path="/github" element={<GitHubStats />} />
          <Route path="/allprojects" element={<ProjectsList />} />
          {/* 404 routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default RouteContainer;
