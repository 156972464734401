import React, { useState, useEffect, useContext } from "react";
import { MdExpandMore } from "react-icons/md";
import { UserContext } from "../../hooks/UserContext"; // Assuming the context is set up correctly
import { api } from "../../services/baseUrl";

const SecondaryContainer = () => {
  const { user, isAuthenticated, token } = useContext(UserContext);
  const [description, setDescription] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [loading, setLoading] = useState(false); // For handling save button loading state

  // Fetch user data only once when the component mounts
  useEffect(() => {
    // Fetch user data
    api
      .get("/homeuser")
      .then((response) => {
        const userData = response.data.data;

        setDescription(
          userData.description_user || "Aucune description disponible."
        );
        setNameUser(userData.name_user || "");
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  // No need to add `user` here, as it won't change often

  return (
    <div id="moi" className="w-full bg-[#44427d] text-white">
      <div className="max-w-screen-xl mx-auto px-8 py-16 text-center md:text-left">
        <h2 className="text-3xl md:text-5xl tracking-wider uppercase text-[#e1927f] font-bold mb-8">
          À propos de moi
        </h2>
        <div className="relative my-8 px-8 py-6 rounded-lg bg-white bg-opacity-10">
          <p className="py-4 max-w-2xl mx-auto text-lg text-white">
            {description || "Aucune description disponible."}
          </p>
        </div>
        <div className="flex items-center justify-center gap-8">
          <a
            href="CV-Ludovic.pdf"
            target="_blank"
            download={false}
            rel="noreferrer"
            className="group flex items-center justify-center my-8 bg-[#e1927f] text-white px-6 py-3 font-semibold uppercase rounded-md tracking-wider cursor-pointer transition-all duration-300 transform hover:bg-[#e1927f] hover:scale-105"
          >
            <p className="text-xs md:text-base lg:text-xl">Regarder mon CV</p>
            <span className="-rotate-90 duration-100 ease-in group-hover:rotate-180">
              <MdExpandMore size={25} />
            </span>
          </a>
          <a
            href="#portfolio"
            className="group flex items-center justify-center my-8 bg-[#e1927f] text-white px-6 py-3 font-semibold uppercase rounded-md tracking-wider cursor-pointer transition-all duration-300 transform hover:bg-[#e1927f] hover:scale-105"
          >
            <p className="text-xs md:text-base lg:text-xl">Projets réalisés</p>
            <span className="-rotate-90 duration-100 ease-in group-hover:-rotate-0">
              <MdExpandMore size={25} />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecondaryContainer;
